import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import {useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {doc, getDoc, setDoc, updateDoc, getDocs, collection, deleteDoc} from 'firebase/firestore';
import {db, storage} from "./firebase";
import Loader from "./components/Loader";
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';

function App() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isLoading, setIsLoading] = useState(false)
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')
    const [level, setLevel] = useState('')
    const [location, setLocation] = useState('')
    const [distance, setDistance] = useState('')
    const [language, setLanguage] = useState('')
    const [introduction, setIntroduction] = useState('')
    const [avatar, setAvatar] = useState('')
    const [data, setData] = useState([])
    const formRef = useRef(null);
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const fileInputRef = useRef(null);
    const [videoFile, setVideoFile] = useState()
    const [videoUrl, setVideoUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const videoInputRef = useRef(null);

    const [isUPIModal, setIsUPIModal] = useState(false)
    const UPIFormRef = useRef(null);
    const [upiid, setUpiid] = useState('')


    const getNextIdAndIncrement = async () => {
        const idDocRef = doc(db, 'counters', 'primaryKeyCounter');
        const idDoc = await getDoc(idDocRef);

        if (idDoc.exists()) {
            const currentId = idDoc.data().currentId;
            await updateDoc(idDocRef, {
                currentId: currentId + 1
            });
            return currentId + 1;
        } else {
            // If the document doesn't exist, create it with an initial value
            await setDoc(idDocRef, {currentId: 1});
            return 1;
        }
    };

// Function to save data with incremented ID
    const saveDataWithIncrementedId = async (collection, data) => {
        try {
            const newId = await getNextIdAndIncrement();
            const newDocRef = doc(db, collection, newId.toString());
            await setDoc(newDocRef, data);
            console.log('Document written with ID: ', newId);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const upiSubmitHandle = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        try {
            setIsLoading(true);
            const newDocRef = doc(db, 'UPI', '1');
            await setDoc(newDocRef, {upi: upiid});
            setIsUPIModal(false);
            setIsLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    const getUPIId = async () => {
        const idDocRef = doc(db, 'UPI', '1');
        const idDoc = await getDoc(idDocRef);
        if (idDoc.data()) {
            setUpiid(idDoc.data().upi)
        }
        console.log(idDoc.data());
    }

    const submitHandle = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        try {
            setIsLoading(true);
            const videostorageRef = ref(storage, `videos/${videoFile.name}`);
            const videouploadTask = uploadBytesResumable(videostorageRef, videoFile);
            const storageRef = ref(storage, `/image/${file.name}`)
            const uploadTask = uploadBytesResumable(storageRef, file);
            videouploadTask.on('state_changed', (snapshot) => {
                // Track upload progress
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                console.log(`Upload is ${progress}% done`);
            }, (error) => {
                console.error('Error uploading file:', error);
            }, async () => {
                // Get download URL and set it to state
                const downloadUrl = await getDownloadURL(videouploadTask.snapshot.ref);
                setVideoUrl(downloadUrl);
                uploadTask.on("state_changed", (snapshot) => {
                    const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    // update progress
                    console.log(percent);
                    // setPercent(percent);
                }, (err) => console.log(err), () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        console.log(url);
                        const data = {
                            id,
                            name,
                            age,
                            gender,
                            level,
                            location,
                            distance,
                            language,
                            introduction,
                            avatar: url,
                            video: downloadUrl,
                        };

                        await saveDataWithIncrementedId('Girls', data);
                        await getData();
                        setId('')
                        setLocation('')
                        setAvatar('')
                        setName('')
                        setAge('')
                        setGender('')
                        setLevel('')
                        setIntroduction('')
                        setLanguage('')
                        setDistance('')
                        if (formRef.current) {
                            formRef.current.reset();
                        }
                        handleClose();
                        setIsLoading(false);
                    });
                });
            });
        } catch (e) {
            console.log(e);
        }
    }

    const fetchAllData = async (collectionName) => {
        const querySnapshot = await getDocs(collection(db, collectionName));
        const dataList = querySnapshot.docs.map(doc => ({
            docId: doc.id, ...doc.data()
        }));
        return dataList;
    };
    const getData = async () => {
        setIsLoading(true);
        const data = await fetchAllData('Girls');
        setData(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
        getUPIId();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
    const handleVideoChange = (e) => {
        const selectedFile = e.target.files[0];
        setVideoFile(selectedFile);
    };

    return (<div className={'container'}>
        <Loader isLoading={isLoading}/>
        <div className="table-container">
            <div className="row justify-content-between mb-3">
                <div className="col-md-6">
                    <h3>Girls List</h3>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-6">
                            <Button className={'form-control'} variant="primary" onClick={handleShow}>
                                Add Record
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <Button className={'form-control'} variant="primary" onClick={() => setIsUPIModal(true)}>
                                Update UPI-ID
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/*Modal*/}
            <Modal size={'lg'} show={isUPIModal} onHide={() => setIsUPIModal(false)}>
                <form onSubmit={upiSubmitHandle} ref={UPIFormRef}>
                    <Modal.Header closeButton>
                        <Modal.Title>UPI ID</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>ID</label>
                                <input type="text" value={upiid} onChange={(e) => setUpiid(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setIsUPIModal(false)}>
                            Close
                        </Button>
                        <Button type={"submit"} variant="primary">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal size={'lg'} show={show} onHide={handleClose}>
                <form onSubmit={submitHandle} ref={formRef}>
                    <Modal.Header closeButton>
                        <Modal.Title>Girl Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label>Profile Image</label>
                                <input type="file" className={'form-control'} required accept="image/*"
                                       onChange={handleFileChange} ref={fileInputRef}/>
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Call Video</label>
                                <input type="file" className={'form-control'} required accept="video/*"
                                       onChange={handleVideoChange} ref={videoInputRef}/>
                                {/*<input type="file" className={'form-control'} required accept="image/*"*/}
                                {/*       onChange={handleFileChange} ref={fileInputRef}/>*/}
                            </div>
                            <div className="col-md-6 form-group">
                                <label>ID</label>
                                <input type="text" value={id} onChange={(e) => setId(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Name <code>*</code></label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Age <code>*</code></label>
                                <input type="number" value={age} onChange={(e) => setAge(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Gender <code>*</code></label>
                                <select className='custom-select' required value={gender}
                                        onChange={(e) => setGender(e.target.value)}>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Level <code>*</code></label>
                                <input type="number" value={level} onChange={(e) => setLevel(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Location <code>*</code></label>
                                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Distance <code>*</code></label>
                                <input type="text" value={distance} onChange={(e) => setDistance(e.target.value)}
                                       className={'form-control'} required placeholder={'...'}/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Languages <code>*</code></label>
                                <input type="text" value={language} onChange={(e) => setLanguage(e.target.value)}
                                       className={'form-control'} required
                                       placeholder={'Hindi,English,Urdu,French'}/>
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Introduction <code>*</code></label>
                                <textarea value={introduction} onChange={(e) => setIntroduction(e.target.value)}
                                          className='form-control' required placeholder='...'></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type={"submit"} variant="primary">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Age</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Level</th>
                    <th scope="col">Introduction</th>
                    <th scope="col">Languages</th>
                    <th scope="col">Distance</th>
                    <th scope="col">Image</th>
                    <th scope="col">Delete</th>
                </tr>
                </thead>
                <tbody>
                {data.length > 0 ? data.map((u, index) => {
                    return (<tr key={index}>
                        <td>{u.id}</td>
                        <td>{u.name}</td>
                        <td>{u.age}</td>
                        <td>{u.gender}</td>
                        <td>LVL {u.level}</td>
                        <td>{u.introduction}</td>
                        <td>{u.language}</td>
                        <td>{u.distance} KM</td>
                        <td>
                            <a target={'_blank'}
                               href={u.avatar ? u.avatar : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.flaticon.com%2Ffree-icon%2Fuser_149071&psig=AOvVaw3feZ2efvGWLwX2gveT5cUS&ust=1721161974758000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCKiDzpPyqYcDFQAAAAAdAAAAABAE"}>
                                <img style={{width: 80, height: 80, borderRadius: "50%",}}
                                     src={u.avatar ? u.avatar : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.flaticon.com%2Ffree-icon%2Fuser_149071&psig=AOvVaw3feZ2efvGWLwX2gveT5cUS&ust=1721161974758000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCKiDzpPyqYcDFQAAAAAdAAAAABAE"}
                                     alt="Profile Image"/>
                            </a>
                        </td>
                        <td>
                            <button className={'btn btn-danger'} onClick={async () => {
                                try {
                                    setIsLoading(true);
                                    await deleteDoc(doc(db, "Girls", u.docId));
                                    await getData();
                                    setIsLoading(false);
                                } catch (error) {
                                    console.error('Error removing document:', error);
                                }
                            }}>Remove
                            </button>
                        </td>
                    </tr>)
                }) : <tr>
                    <td colSpan={10} className={'text-center'}>No Record Found!</td>
                </tr>}
                </tbody>
            </Table>
        </div>
    </div>);
}

export default App;
